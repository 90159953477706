import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-4915330b"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "myClass" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_resp_menu = _resolveComponent("resp-menu");
    const _component_template_header_menu = _resolveComponent("template-header-menu");
    const _component_template_resp_header = _resolveComponent("template-resp-header");
    const _component_header_logged_in = _resolveComponent("header-logged-in");
    const _component_navigation_bar = _resolveComponent("navigation-bar");
    const _component_router_view = _resolveComponent("router-view");
    const _component_template_impressum = _resolveComponent("template-impressum");
    const _component_footer_logged_in = _resolveComponent("footer-logged-in");
    const _component_ion_content = _resolveComponent("ion-content");
    const _component_ion_page = _resolveComponent("ion-page");
    const _component_IonApp = _resolveComponent("IonApp");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_IonApp, null, {
            default: _withCtx(() => [
                (!_ctx.store.state.loggedBahnID)
                    ? (_openBlock(), _createBlock(_component_resp_menu, {
                        key: 0,
                        appDBConfig: _ctx.appDBConfig
                    }, null, 8, ["appDBConfig"]))
                    : _createCommentVNode("", true),
                _createVNode(_component_ion_page, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_ion_content, {
                            fullscreen: "true",
                            id: "main-content"
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", {
                                    id: "contentAll",
                                    class: _normalizeClass({ contentBoxShadow: true }),
                                    style: _normalizeStyle({ 'max-width': _ctx.appDBConfig.viewport })
                                }, [
                                    (!_ctx.store.state.loggedBahnID)
                                        ? (_openBlock(), _createBlock(_component_template_header_menu, {
                                            key: 0,
                                            appDBConfig: _ctx.appDBConfig
                                        }, null, 8, ["appDBConfig"]))
                                        : _createCommentVNode("", true),
                                    (!_ctx.store.state.loggedBahnID)
                                        ? (_openBlock(), _createBlock(_component_template_resp_header, {
                                            key: 1,
                                            appDBConfig: _ctx.appDBConfig
                                        }, null, 8, ["appDBConfig"]))
                                        : _createCommentVNode("", true),
                                    (_ctx.store.state.loggedBahnID)
                                        ? (_openBlock(), _createBlock(_component_header_logged_in, {
                                            key: 2,
                                            onLogout: _cache[0] || (_cache[0] = ($event) => (_ctx.logoutBahnID()))
                                        }))
                                        : _createCommentVNode("", true),
                                    false
                                        ? (_openBlock(), _createBlock(_component_navigation_bar, { key: 3 }))
                                        : _createCommentVNode("", true),
                                    _createElementVNode("div", null, [
                                        _createElementVNode("div", null, [
                                            _createVNode(_component_router_view, {
                                                role: "main",
                                                "aria-label": "Inhalt",
                                                appDBConfig: _ctx.appDBConfig
                                            }, {
                                                default: _withCtx(({ Component }) => [
                                                    (_openBlock(), _createBlock(_KeepAlive, null, [
                                                        (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                                                    ], 1024))
                                                ]),
                                                _: 1
                                            }, 8, ["appDBConfig"])
                                        ]),
                                        (!_ctx.store.state.loggedBahnID)
                                            ? (_openBlock(), _createBlock(_component_template_impressum, { key: 0 }))
                                            : _createCommentVNode("", true),
                                        (_ctx.store.state.loggedBahnID)
                                            ? (_openBlock(), _createBlock(_component_footer_logged_in, { key: 1 }))
                                            : _createCommentVNode("", true)
                                    ])
                                ], 4)
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                })
            ]),
            _: 1
        })
    ]));
}
