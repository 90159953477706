import { createStore, useStore as baseUseStore, } from "vuex";
export const key = Symbol();
const state = {
    loggedBahnID: true,
    lang: "de",
};
export var MUTATIONS;
(function (MUTATIONS) {
    MUTATIONS["EDIT_LANG"] = "EDIT_LANG";
    MUTATIONS["setLoggedBahnID"] = "setLoggedBahnID";
})(MUTATIONS || (MUTATIONS = {}));
var MUTATIONS_INTERN;
(function (MUTATIONS_INTERN) {
})(MUTATIONS_INTERN || (MUTATIONS_INTERN = {}));
export var ACTIONS;
(function (ACTIONS) {
    ACTIONS["resetStore"] = "resetStore";
})(ACTIONS || (ACTIONS = {}));
const actions = {
    [ACTIONS.resetStore](state) {
        state.commit(MUTATIONS.setLoggedBahnID, false);
    },
};
const mutations = {
    [MUTATIONS.EDIT_LANG](state, lang) {
        state.lang = lang;
    },
    [MUTATIONS.setLoggedBahnID](state, loggedBahnID) {
        state.loggedBahnID = loggedBahnID;
        //state.loggedBahnID = true;
    },
};
export const store = createStore({ state, mutations, actions });
// our own useStore function for types
export function useStore() {
    return baseUseStore(key);
}
