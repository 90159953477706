import { createRouter, createWebHistory } from "@ionic/vue-router";
import { useStore } from "@/store";
const store = useStore();
const routes = [
    {
        path: "",
        name: "Startseite",
        component: () => import("../views/Startseite.vue"),
    },
    {
        path: "/:catchAll(.*)",
        name: "Redirect 404",
        redirect: "/",
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
export default router;
