import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-45c0f927"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    id: "navBar"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_router_link = _resolveComponent("router-link");
    return true
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("ul", null, [
                _createElementVNode("li", null, [
                    _createVNode(_component_router_link, {
                        class: _normalizeClass(["navElement", { active: _ctx.route.path === '/menu', fontDbT3Head: _ctx.route.path === '/menu', fontDbT3: _ctx.route.path !== '/menu' }]),
                        to: "/menu"
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode("Übersicht")
                        ]),
                        _: 1
                    }, 8, ["class"])
                ]),
                _createElementVNode("li", null, [
                    _createVNode(_component_router_link, {
                        class: _normalizeClass(["navElement", { active: _ctx.route.path === '/fahrten', fontDbT3Head: _ctx.route.path === '/fahrten', fontDbT3: _ctx.route.path !== '/fahrten' }]),
                        to: "/fahrten"
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode("Meine Fahrten")
                        ]),
                        _: 1
                    }, 8, ["class"])
                ]),
                _createElementVNode("li", null, [
                    _createVNode(_component_router_link, {
                        class: _normalizeClass(["navElement", { active: _ctx.route.path === '/ortsfavoriten', fontDbT3Head: _ctx.route.path === '/ortsfavoriten', fontDbT3: _ctx.route.path !== '/ortsfavoriten' }]),
                        to: "/ortsfavoriten"
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode("Ortsfavoriten")
                        ]),
                        _: 1
                    }, 8, ["class"])
                ]),
                _createElementVNode("li", null, [
                    _createVNode(_component_router_link, {
                        class: _normalizeClass(["navElement", { active: _ctx.route.path === '/profil', fontDbT3Head: _ctx.route.path === '/profil', fontDbT3: _ctx.route.path !== '/profil' }]),
                        to: "/profil"
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode("Profil")
                        ]),
                        _: 1
                    }, 8, ["class"])
                ])
            ])
        ]))
        : _createCommentVNode("", true);
}
